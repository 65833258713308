<script setup>
import { useCustomerStore } from '~/stores/account/customers.store'
import MobileAccountCustomersCard from './partials/mobile-account-customers-card.vue'
import MobileAccountCustomersFilter from './partials/filters/index.vue'

const customerStore = useCustomerStore();
const customers = ref(undefined);
const route = useRoute();
const router = useRouter();
const page = ref(route.query?.page || 1);
const scrollPosition = ref(0);
const isLoadingPage = ref(true);
const isLoadingData = ref(false);
const isAtBottom = ref(false);
const lastPage = ref(false);
const selectedCustomerId = ref(null);
const optionsCustomerId = ref(null);
const fetchCustomers = async () => {
  return await customerStore.fetchCustomers(route.query?.page || page.value)
};
const checkScroll = () => {
  scrollPosition.value = window.scrollY;
  isAtBottom.value = window.innerHeight + window.scrollY >= document.body.offsetHeight;
};
const handleToggleContent = (customerId) => {
  // If the same customer is clicked, close it; otherwise, open the clicked one.
  selectedCustomerId.value = selectedCustomerId.value === customerId ? null : customerId;
};
const handleToggleOptions = (customerId) => {
  // Toggle options for a customer, close it if clicked again.
  optionsCustomerId.value = optionsCustomerId.value === customerId ? null : customerId;
};

onMounted(async () => {
  customers.value = await fetchCustomers();
  window.addEventListener('scroll', checkScroll);
  isLoadingPage.value = false;
});

watch(isAtBottom, async () => {
  if (isLoadingData.value || customers.value.pagination?.lastPage === page.value) {
    return;
  }

  isLoadingData.value = true;

  // Update query
  await router.push({
    query: { ...route.query, page: ++page.value },
  });

  // Fetch and update new page data
  const nextPageCustomers = await fetchCustomers();

  if (nextPageCustomers.data?.length) {
    customers.value.data.push(...nextPageCustomers.data);
  }

  isLoadingData.value = false;
});
</script>
<template>
  <NuxtLayout name="mobile-base">
    <template #mobile-header>
      <mobile-header :title="$t('client.customers')">
        <template #header-left>
          <mobile-back-button></mobile-back-button>
        </template>
        <template #header-right>
          <!-- <mobile-account-customers-filter></mobile-account-customers-filter> -->
        </template>
      </mobile-header>
    </template>

    <template v-if="isLoadingPage">
      <div class="relative h-screen">
        <div class="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
          <span class="loader"></span>
        </div>
      </div>
    </template>

    <template v-else>
      <template v-if="customers?.data?.length">
        <template v-for="customer in customers?.data" :key="customer.id">
          <mobile-account-customers-card :data="customer" :is-expanded="customer.id === selectedCustomerId"
            :is-options-expanded="customer.id === optionsCustomerId" @toggleContent="handleToggleContent(customer.id)"
            @toggleOptions="handleToggleOptions(customer.id)"></mobile-account-customers-card>
        </template>
        <div class="absolute -bottom-5 left-1/2 -translate-x-1/2">
          <span class="loader" v-if="isLoadingData"></span>
        </div>
      </template>
      <template v-else>
        No Customers
      </template>
    </template>

    <template #mobile-footer>
      <div class="fixed bottom-0 bg-white p-4 w-full">
        <div class="flex items-center gap-3">
          <NuxtLink :to="localePath({ name: 'account.customers.create' })"
            class="bg-indigo-900 text-white rounded-lg p-3 capitalize  w-full text-center">
            <i class="fa-solid fa-plus me-3"></i>
            {{ $t('client.add_new_customer') }}
          </NuxtLink>
        </div>
      </div>
    </template>
  </NuxtLayout>
</template>